import { Button, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Container from '../../components/Container';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import DividerTitle from '../../components/DiviverTitle';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TestService from '../../services/testService';
import { useMutation, useQuery } from 'react-query';
import { ITestResponse } from '../../services/testService';

interface IRedirectProps {
	customStyles?: React.CSSProperties;
}

const Redirect = ({ customStyles }: IRedirectProps) => {
	const location = useLocation();

	const navigate = useNavigate();

	const { refetch } = useQuery<ITestResponse[], Error>('test', () => TestService.getTests());

	const mutation = useMutation({
		mutationFn: TestService.updateTest,
		onSuccess: () => {
			navigate('/');
			refetch();
		}
	});

	return (
		<Container width="1056px">
			<div style={customStyles}>
				<div style={{ display: 'flex' }}>
					<div onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginRight: '10px' }}>
						<ArrowBack sx={{ color: '#465EFF', fontSize: '40px' }} />
					</div>
					<div>
						<Title>Protótipo</Title>
						<DividerTitle />
					</div>
				</div>
				<Stack sx={{ alignItems: 'center', marginTop: '48px' }}>
					<h2 style={{ fontFamily: 'bb-text-regular', fontSize: '28px', textAlign: 'center' }}>
						Satisfação com a Plataforma BB Testers
					</h2>
					<div style={{ marginTop: '18px' }}>
						<Paragraph fontSize="18px" style={{ textAlign: 'center', width: '704px' }}>
							Para melhorar sua experiência, o teste está disponível em uma plataforma externa. Basta clicar no botão
							abaixo e o teste será aberto em uma nova janela.
						</Paragraph>
					</div>
					<a href={location.state.url} target="_blank">
						<Button
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								borderRadius: '4px',
								padding: '10px 32px',
								backgroundColor: '#FCFC30',
								marginTop: '24px',
								color: '#465EFF'
							}}
							onClick={() => {
								mutation.mutate({ id_test: location.state.id_test });
							}}
						>
							ABRIR TESTE
						</Button>
					</a>
				</Stack>
			</div>
		</Container>
	);
};

export default Redirect;
