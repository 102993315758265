import { Paper, Button } from '@mui/material';
import { Theme } from '@mui/material';
import Title from '../Title';
import Paragraph from '../Paragraph';
// import useStyles from './styles';
import { useTheme } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import CompleteProfileIllustrator from '../../assets/images/svgs/complete-profile.svg';

const CompleteProfile = () => {
	// const styles = useStyles();

	const theme = useTheme<Theme>();

	const navigate = useNavigate();

	return (
		<Paper>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginTop: '48px',
					backgroundColor: '#F9F7FF',
					padding: '24px 32px'
				}}
			>
				<img style={{ marginRight: '32px' }} src={CompleteProfileIllustrator} alt="Ilustração complete perfil" />
				<div>
					<Title fontSize="18px" color="#111214">
						Questionário de perfil
					</Title>
					<Paragraph fontSize="16px" style={{ color: '#66707D', marginTop: '10px' }}>
						Você já faz parte da comunidade SegTesters da BB Seguros! Agora compartilhe com a gente mais informações
						sobre você e seu perfil. Elas são muito importantes para construirmos produtos cada vez melhores, que cuidam
						e protegem as conquistas de mais de 1 milhão de segurados.
					</Paragraph>
					<Button
						sx={{
							fontFamily: 'bb-title-bold',
							bgcolor: theme.palette.secondary.main,
							padding: '10px 16px',
							marginTop: '16px'
						}}
						onClick={() => navigate('/perguntas')}
					>
						INICIAR QUESTIONÁRIO
					</Button>
				</div>
			</div>
		</Paper>
	);
};

export default CompleteProfile;
