import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Container from '../../components/Container';
import DividerTitle from '../../components/DiviverTitle';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import { forwardRef } from 'react';

interface IFaqProps {
	customStyles?: React.CSSProperties;
}

const Faq = forwardRef<HTMLElement, IFaqProps>((props, ref) => {
	const mobile = useMediaQuery('(max-width: 1076px)');

	return (
		<Container
			width="1056px"
			style={{ paddingTop: '80px', paddingBottom: mobile ? '32px' : '80px', ...props.customStyles }}
		>
			<section ref={ref}>
				<Title fontSize={mobile ? '22px' : '32px'}>Perguntas frequentes</Title>
				<DividerTitle />
				<div>
					<Accordion disableGutters defaultExpanded elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Preciso trabalhar com tecnologia ou seguros para ser Seg Tester?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Não. Qualquer pessoa que queira colaborar com sugestões e testar novas funcionalidades pode ser Beta
								Tester. Você não precisa ser especialista em tecnologia nem em seguros.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Como vou realizar os testes e responder as pesquisas?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Após o cadastro, você vai ter acesso à área de testes da comunidade. Lá estarão os questionários abertos
								para preenchimento. Basta escolher um e iniciar.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Onde os testes serão disponibilizados?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Os testes estarão sempre disponíveis na área de testes do portal SegTesters. Sempre que um novo
								questionário for publicado, você receberá um e-mail informando que há testes abertos para preenchimento.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								As pesquisas podem ser respondidas de forma anônima?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Sim. Prezamos muito pela segurança de seus dados e garantimos que eles estão seguros na Comunidade. Nós
								sempre analisamos o conjunto de todos os dados extraídos e não temos acesso às respostas individuais de
								cada usuário.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								O que é feito com meus dados e minhas informações?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								A partir dos testes geramos insights para melhoria dos produtos e serviços da BB Seguros. É nosso
								compromisso proteger os dados que coletamos, zelando pelo uso ético, transparente e responsável dessas
								informações.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Poderei excluir meu cadastro, se desejar, no futuro?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Sim. Caso você queira excluir seu cadastro, acesse o "Menu" da área de testes e clique em "Acesse sua
								conta". Você sempre será bem-vindo(a) caso queira retornar para a comunidade!
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Vou ser obrigado a responder todas as pesquisas e testes?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Não. Caso alguma pesquisa ou teste esteja disponível mas você não tenha conhecimento sobre o assunto ou
								não deseja compartilhar com a gente sua experiência, é só deixar o teste em aberto.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
					<Accordion disableGutters elevation={0}>
						<AccordionSummary expandIcon={<ExpandMore sx={{ color: '#465EFF' }} />} sx={{ padding: '15px 0' }}>
							<Title fontSize={mobile ? '16px' : '20px'} color="#111214">
								Utilizo o App BB. Vou ter acesso a funcionalidades exclusivas antes de todo mundo?
							</Title>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 0 10px 0' }}>
							<Paragraph fontSize={mobile ? '14px' : '16px'}>
								Não. Mesmo que você já seja um cliente BB, você não terá acesso a funcionalidades em versão beta dentro
								do App BB. Você colabora no desenvolvimento de novos produtos mas não testa funcionalidades que já estão
								em fase de implantação no App BB.
							</Paragraph>
						</AccordionDetails>
					</Accordion>
				</div>
			</section>
		</Container>
	);
});

export default Faq;
