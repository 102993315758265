import Title from '../Title';
import { Close } from '@mui/icons-material';
import Paragraph from '../Paragraph';
import { Button, Dialog, IconButton } from '@mui/material';
import useStyles from './styles';

interface IRedirectMobileModalProps {
	redirectMobileModalOpen: boolean;
	setRedirectMobileModalOpen: (value: boolean) => void;
	redirectMobileModalUrl: string;
}

const RedirectMobileModal = ({
	redirectMobileModalOpen,
	setRedirectMobileModalOpen,
	redirectMobileModalUrl
}: IRedirectMobileModalProps) => {
	const styles = useStyles();

	return (
		<Dialog open={redirectMobileModalOpen} fullScreen>
			<div className={styles.prototipoWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Protótipo
					</Title>
					<IconButton onClick={() => setRedirectMobileModalOpen(false)}>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
			</div>
			<div className={styles.contentWrapper}>
				<Title color="#111214" fontSize="22px">
					Satisfação com a Plataforma BB Testers
				</Title>
				<Paragraph style={{ marginTop: '16px' }}>
					Para melhorar sua experiência, o teste está disponível em uma plataforma externa. Basta clicar no botão abaixo
					e o teste será aberto em uma nova janela.
				</Paragraph>
				<a href={redirectMobileModalUrl} target="_blank">
					<Button
						fullWidth
						sx={{
							fontFamily: 'bb-title-bold',
							fontSize: '16px',
							borderRadius: '4px',
							padding: '10px 32px',
							backgroundColor: '#FCFC30',
							marginTop: '24px',
							color: '#465EFF',
							'&:hover': {
								backgroundColor: '#FCFC30'
							}
						}}
					>
						ABRIR TESTE
					</Button>
				</a>
			</div>
		</Dialog>
	);
};

export default RedirectMobileModal;
