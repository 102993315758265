import { Button, Grid, useMediaQuery } from '@mui/material';
import { forwardRef } from 'react';
import Container from '../../components/Container';
import {
	WhatIsIllustration,
	VoiceIllustration,
	NewsIllustration,
	AdvantageIllustration
} from '../../assets/images/images';
import DividerTitle from '../../components/DiviverTitle';
import Title from '../../components/Title';
import useStyles from './styles';
import Paragraph from '../../components/Paragraph';
import BenefitCard from '../../components/BenefitCard';
import { useNavigate } from 'react-router';
import Carousel, { DotProps } from 'react-multi-carousel';

interface IWhatIsProps {
	customStyles?: React.CSSProperties;
	fullpage?: boolean;
	scrollToFaq?: () => void;
}

const CustomDots = ({ onClick, ...rest }: { onClick?: React.MouseEventHandler<HTMLDivElement> }) => {
	const { active } = rest as DotProps;

	return (
		<>
			{active ? (
				<div
					onClick={onClick}
					style={{
						height: '6px',
						width: '24px',
						backgroundColor: '#3354FD',
						marginRight: '8px',
						borderRadius: '5px'
					}}
				></div>
			) : (
				<div
					onClick={onClick}
					style={{
						height: '6px',
						width: '6px',
						backgroundColor: '#0000001A',
						marginRight: '8px',
						borderRadius: '50%'
					}}
				></div>
			)}
		</>
	);
};

const WhatIs = forwardRef<HTMLElement, IWhatIsProps>(({ customStyles, fullpage, scrollToFaq }, ref) => {
	const styles = useStyles();

	const navigate = useNavigate();

	const mobile = useMediaQuery('(max-width: 1076px)');

	const responsive = {
		mobile: {
			breakpoint: { max: 1076, min: 0 },
			items: 1
		}
	};

	return (
		<Container
			width="1056px"
			style={{
				paddingTop: '80px',
				paddingBottom: mobile ? '10px' : '80px',
				...customStyles
			}}
		>
			<section ref={ref} className={styles.whatIs}>
				<Grid container spacing={30}>
					{!mobile && (
						<Grid item xs={4}>
							<div>
								<img src={WhatIsIllustration} alt="Info Beta" />
							</div>
						</Grid>
					)}

					<Grid item xs={mobile ? 12 : 8}>
						<div>
							<div>
								<Title fontSize={mobile ? '22px' : '32px'}>Por que ser SegTester?</Title>
								<DividerTitle />
								<Paragraph fontSize="18px">
									SegTesters são pessoas que testam funcionalidades, exprimindo sua opinião e colaborando na construção
									de novos produtos e serviços.
								</Paragraph>
							</div>
							<div className={styles.infoWrapper}>
								<Title fontSize={mobile ? '22px' : '32px'}>Qual é sua função?</Title>
								<DividerTitle />
								<Paragraph fontSize="18px">
									Consideramos a opinião e a experiência dos SegTesters essenciais para a evolução da BBSeguros.
									Queremos ter certeza de que nossos serviços e funcionalidades sejam intuitivos e úteis aos nossos
									clientes.
								</Paragraph>
								{!mobile && (
									<Button
										sx={{
											backgroundColor: '#E4ECFF',
											marginTop: '32px',
											fontFamily: 'bb-title-bold',
											padding: '9px 16px',
											letterSpacing: '0.5%',
											color: '#465EFF'
										}}
										onClick={() => (fullpage ? scrollToFaq!() : navigate('/faq'))}
									>
										VER PERGUNTAS MAIS FREQUENTES
									</Button>
								)}
							</div>
						</div>
					</Grid>
				</Grid>
				<div className={styles.benefits}>
					<Title fontSize={mobile ? '22px' : '32px'}>Benefícios</Title>
					<DividerTitle color="#FCFC30" />
					{mobile ? (
						<div style={{ position: 'relative', paddingBottom: '30px' }}>
							<Carousel
								responsive={responsive}
								renderDotsOutside={true}
								showDots={true}
								customDot={<CustomDots />}
								dotListClass={styles.customDotClass}
								arrows={false}
								autoPlay
								infinite
							>
								<BenefitCard
									imageSrc={VoiceIllustration}
									title="Tenha voz ativa"
									text="Colabore com suas idéias e opiniões no desenvolvimento de novos produtos e serviços."
									textColor="#4E5155"
								/>
								<BenefitCard
									imageSrc={NewsIllustration}
									title="Descubra novidades"
									text="Veja funcionalidades que estão sendo construídas e ainda não foram lançados ao público."
									textColor="#4E5155"
								/>
								<BenefitCard
									imageSrc={AdvantageIllustration}
									title="Clube de vantagens"
									text="Os SegTesters aproveitam as vantagens exclusivas em produtos do BB e de empresas parceiras."
									textColor="#4E5155"
								/>
							</Carousel>
						</div>
					) : (
						<Grid container spacing={3}>
							<Grid item xs={4}>
								<BenefitCard
									imageSrc={VoiceIllustration}
									title="Tenha voz ativa"
									text="Colabore com suas ideias e opiniões no desenvolvimento de novos produtos e serviços."
								/>
							</Grid>
							<Grid item xs={4}>
								<BenefitCard
									imageSrc={NewsIllustration}
									title="Descubra novidades"
									text="Veja funcionalidades que estão sendo construídas e ainda não foram lançadas ao público."
								/>
							</Grid>
							<Grid item xs={4}>
								<BenefitCard
									imageSrc={AdvantageIllustration}
									title="Clube de vantagens"
									text="Os SegTesters aproveitam as vantagens exclusivas em produtos do BB e de empresas parceiras."
								/>
							</Grid>
						</Grid>
					)}
				</div>
			</section>
		</Container>
	);
});

export default WhatIs;
