import { ReactNode, useState } from 'react';
import { Tabs, Tab, useMediaQuery, Skeleton } from '@mui/material';
import { Edit, Check, ArrowForwardIos } from '@mui/icons-material';
import { useQuery } from 'react-query';
import Carousel, { DotProps } from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import secureStorage from 'react-secure-storage';
import Container from '../../components/Container';
import DividerTitle from '../../components/DiviverTitle';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import CompleteProfile from '../../components/CompleteProfile';
import TestCard from '../../components/TestCard';
import TestService, { ITestResponse } from '../../services/testService';
import 'react-multi-carousel/lib/styles.css';
import ArrowButton from '../../components/ArrowButton';
import useStyles from './styles';
import { PayloadProfile } from '../../utils/decodeToken';
import RedirectMobileModal from '../../components/RedirectMobileModal';
import ProfileQuestionsOverlay from '../../components/ProfileQuestionsOverlay';
import ModalQuestionMobile from '../../components/ModalQuestionMobile';
import OverlayQuitQuestionMobile from '../../components/OverlayQuitQuestionsMobile';
import useBetaTesters from '../../contexts/useBetaTestersContext';

const TabLabel = ({ icon, label }: { icon: ReactNode; label: string }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div style={{ marginRight: '4px' }}>{icon}</div>
			<span style={{ fontFamily: 'bb-title-bold' }}>{label}</span>
		</div>
	);
};

const CustomDots = ({ onClick, ...rest }: { onClick?: React.MouseEventHandler<HTMLDivElement> }) => {
	const { active } = rest as DotProps;

	return (
		<>
			{active ? (
				<div
					onClick={onClick}
					style={{
						height: '6px',
						width: '24px',
						backgroundColor: '#3354FD',
						marginRight: '8px',
						borderRadius: '5px'
					}}
				></div>
			) : (
				<div
					onClick={onClick}
					style={{
						height: '6px',
						width: '6px',
						backgroundColor: '#0000001A',
						marginRight: '8px',
						borderRadius: '50%'
					}}
				></div>
			)}
		</>
	);
};

const MyTests = () => {
	const [value, setValue] = useState<number>(0);
	const [redirectMobileModalOpen, setRedirectMobileModalOpen] = useState<boolean>(false);
	const [redirectMobileModalUrl, setRedirectMobileModalUrl] = useState<string>('');
	const [profileQuestionsOverlayOpen, setProfileQuestionsOverlayOpen] = useState<boolean>(false);
	const [quitQuestionMobileOverlayOpen, setQuitQuestionMobileOverlayOpen] = useState<boolean>(false);

	const tests = useQuery<ITestResponse[], Error>('test', () => TestService.getTests());

	const styles = useStyles();

	const navigate = useNavigate();

	const { questionMobileModalOpen, setQuestionMobileModalOpen } = useBetaTesters();

	const mobile = useMediaQuery('(max-width: 800px)');

	const handleChange = (event: React.SyntheticEvent<Element, Event>, value: number) => {
		setValue(value);
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1
		}
	};

	const { txt_question_status } = secureStorage.getItem('userProfile') as PayloadProfile;

	return (
		<>
			<RedirectMobileModal
				redirectMobileModalOpen={redirectMobileModalOpen}
				setRedirectMobileModalOpen={setRedirectMobileModalOpen}
				redirectMobileModalUrl={redirectMobileModalUrl}
			/>
			<OverlayQuitQuestionMobile
				modalOpen={quitQuestionMobileOverlayOpen}
				setModalOpen={setQuitQuestionMobileOverlayOpen}
				quitButtonFn={() => {
					setQuestionMobileModalOpen(false);
					setQuitQuestionMobileOverlayOpen(false);
				}}
			/>
			<ProfileQuestionsOverlay
				profileQuestionsOverlayOpen={profileQuestionsOverlayOpen}
				setProfileQuestionsOverlayOpen={setProfileQuestionsOverlayOpen}
				setQuestionMobileModalOpen={setQuestionMobileModalOpen}
			/>

			<ModalQuestionMobile
				questionMobileModalOpen={questionMobileModalOpen}
				setQuitQuestionMobileOverlayOpen={setQuitQuestionMobileOverlayOpen}
			/>
			<Container width="1056px" style={{ paddingTop: '148px', paddingBottom: '80px' }}>
				<Title>Meus testes</Title>
				<DividerTitle />
				<Paragraph fontSize="18px">
					Esta é sua página de testes. Aqui, você pode visualizar e responder aos questionários disponíveis. Não se
					preocupe se não conseguir preencher todos: novos testes são publicados regularmente, e você receberá um e-mail
					quando um novo teste for disponibilizado.
				</Paragraph>
				{txt_question_status === 'IN' && <CompleteProfile />}
				<div style={{ marginTop: '48px' }}>
					<div style={{ borderBottom: '1px solid #B4B9C1' }}>
						<Tabs value={value} onChange={handleChange}>
							<Tab label={<TabLabel label="ABERTOS" icon={<Edit />} />} />
							<Tab label={<TabLabel label="FINALIZADOS" icon={<Check />} />} />
						</Tabs>
					</div>
					<div style={{ marginTop: '48px' }}>
						<Carousel
							responsive={responsive}
							customLeftArrow={<ArrowButton />}
							customRightArrow={<ArrowButton side="right" />}
							customDot={<CustomDots />}
							renderDotsOutside={true}
							dotListClass={styles.customDotClass}
							showDots={false}
							arrows
						>
							{value === 0 ? (
								tests.isLoading ? (
									<Skeleton variant="rounded" animation="wave" width={264} height={232} />
								) : (
									tests.data?.map(({ dt_conclusao, test }) => {
										if (dt_conclusao === null) {
											return (
												<TestCard
													key={`item-${test.id}`}
													title={test.txt_title}
													status={`Disponível até ${new Date(test.dt_end!).toLocaleDateString('pt-BR')}`}
													icon={<ArrowForwardIos sx={{ color: '#465EFF' }} />}
													onClick={() => {
														if (mobile) {
															setRedirectMobileModalOpen(true);
															setRedirectMobileModalUrl(test.txt_url);
														} else {
															navigate('/acessar-teste', {
																state: { url: test.txt_url, id_test: test.id }
															});
														}
													}}
												/>
											);
										}
									})
								)
							) : tests.isLoading ? (
								<div>Carregando...</div>
							) : (
								tests.data?.map(({ dt_conclusao, test }) => {
									if (dt_conclusao) {
										return (
											<TestCard
												disabled={true}
												key={`item-${test.id}`}
												title={test.txt_title}
												status="Finalizado"
												icon={<Check sx={{ color: '#888D95' }} />}
											/>
										);
									}
								})
							)}
						</Carousel>
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
					<Paragraph fontSize="16px">
						Nunca iremos solicitar senhas ou códigos de segurança em nossas pesquisas.
					</Paragraph>
				</div>
			</Container>
		</>
	);
};

export default MyTests;
