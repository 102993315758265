import { Theme } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { IntroImage, IntroMobileImage } from '../../assets/images/images';

const useStyles = makeStyles((theme: Theme) => ({
	introWrapper: {
		padding: '60px 10px 0 10px',
		'@media (max-width: 1076px)': {
			padding: '0'
		}
	},
	intro: {
		width: '100%',
		height: '570px',
		background: `url(${IntroImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		display: 'flex',
		alignItems: 'center',
		'@media (max-width: 1076px)': {
			background: `url(${IntroMobileImage})`,
			backgroundRepeat: 'no-repeat',
			backgorundPosition: 'center',
			width: '100%',
			height: '700px',
			backgroundSize: 'cover'
		}
	},
	introTextWrapper: {
		marginLeft: 120,
		'@media (max-width: 1076px)': {
			marginLeft: '16px'
		}
	},
	introText: {
		fontFamily: 'bb-title-bold',
		fontSize: '40px',
		lineHeight: '50px',
		maxWidth: 450,
		color: '#FDFEFF',
		'@media (max-width: 1076px)': {
			fontSize: '30px',
			maxWidth: 350
		}
	},
	sublimeText: {
		fontSize: '48px',
		display: 'inline-block',
		position: 'relative',
		zIndex: 1,
		color: theme.palette.secondary.main,
		'@media (max-width: 1076px)': {
			fontSize: '30px'
		},

		'&::before': {
			content: '""',
			border: `10px solid ${theme.palette.primary.main}`,
			width: 'calc(100% + 5px)',
			display: 'block',
			position: 'absolute',
			zIndex: -1,
			left: -10,
			top: 35,
			'@media (max-width: 1076px)': {
				top: 25,
				border: `7px solid ${theme.palette.primary.main}`,
				width: '100px',
				left: 0
			}
		}
	},
	signUp: {
		marginTop: '80px',
		'@media (max-width: 1076px)': {
			marginTop: '32px'
		}
	},
	dogImage: {
		width: '100%'
	},
	formWrapper: {
		marginTop: '32px'
	},
	registerButtonWrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: '32px'
	},
	contactWrapper: {
		backgroundColor: '#F9F7FF',
		marginTop: '80px',
		padding: '40px 0',
		'@media (max-width: 1076px)': {
			marginTop: '32px'
		}
	},
	phoneContact: {
		display: 'flex',
		alignItems: 'center'
	},
	locationContact: {
		display: 'flex',
		alignItems: 'center'
	},
	footer: {
		backgroundColor: theme.palette.primary.main,
		padding: '25px 0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	footerTitle: {
		fontFamily: 'bb-title-medium',
		fontSize: '20px',
		color: '#FDFEFF'
	}
}));

export default useStyles;
