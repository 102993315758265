import useStyles from './styles';

const FooterBeta = () => {
	const styles = useStyles();

	return (
		<footer className={styles.footer}>
			<div>
				<span className={styles.footerSpan}>Portal Beta Testers v 1.2</span>
			</div>
			<div>
				<span className={styles.footerSpan}>© 2023 BB Seguridade Participações</span>
			</div>
		</footer>
	);
};

export default FooterBeta;
