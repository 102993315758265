import { useRef, useState } from 'react';
import { Grid, TextField, Button, Stack, useMediaQuery, Checkbox, FormControlLabel } from '@mui/material';
import { Phone, LocationOn } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import useStyles from './styles';
import Container from '../../components/Container';
import WhatIs from '../WhatIs';
import {
	DogImage,
	FacebookIcon,
	InstagramIcon,
	LinkedinIcon,
	LogoBBBlue,
	YoutubeIcon
} from '../../assets/images/images';
import Title from '../../components/Title';
import DividerTitle from '../../components/DiviverTitle';
import Paragraph from '../../components/Paragraph';
import Faq from '../Faq';
import ModalLogin from '../../components/ModalLogin';
import { maskCpf, unmaskCpf } from '../../utils/maskCpf';
import Loading from '../../components/Loading';
import NavBarPublic from '../../components/NavbarPublic';
import SignUpService from '../../services/betatesterService';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface IFormSignInInput {
	nr_cpf: string;
	txt_name: string;
	txt_email: string;
	dt_birth: string;
}

const HomePublic = () => {
	const [openModal, setOpenModal] = useState(false);
	const whatIsSectionRef = useRef<HTMLElement>(null);
	const signUpSectionRef = useRef<HTMLElement>(null);
	const faqSectionRef = useRef<HTMLElement>(null);

	const styles = useStyles();

	const theme = useTheme<Theme>();

	const mobile = useMediaQuery('(max-width: 1076px)');

	const { control, handleSubmit } = useForm<IFormSignInInput>({
		defaultValues: {
			nr_cpf: '',
			txt_name: '',
			txt_email: '',
			dt_birth: ''
		}
	});

	const mutation = useMutation({
		mutationFn: SignUpService.signUp,
		onSuccess: () => {
			toast('Cadastro efetuado!', { type: 'success' });
			setOpenModal(true);
		},
		onError: (error: AxiosError<{ message: string }>) => {
			toast(error.response?.data.message, { type: 'error' });
		}
	});

	const onSubmit = (data: IFormSignInInput) => {
		const clearCpf = unmaskCpf(data.nr_cpf);

		mutation.mutate({ ...data, nr_cpf: clearCpf });
	};

	const scrollToWhatIs = () => {
		const scrollTop = whatIsSectionRef.current?.offsetTop as number;
		console.log(scrollTop);
		window.scrollTo({ top: scrollTop, behavior: 'smooth' });
	};

	const scrollToSignUp = () => {
		const scrollTop = signUpSectionRef.current?.offsetTop as number;
		console.log(scrollTop);
		window.scrollTo({ top: scrollTop - 80, behavior: 'smooth' });
	};

	const scrollToFaq = () => {
		const scrollTop = faqSectionRef.current?.offsetTop as number;
		console.log(scrollTop);
		window.scrollTo({ top: scrollTop - 120, behavior: 'smooth' });
	};

	return (
		<>
			{mutation.isLoading && <Loading />}
			<ModalLogin open={openModal} setOpen={setOpenModal} />
			<NavBarPublic
				scrollToWhatIs={scrollToWhatIs}
				scrollToSignUp={scrollToSignUp}
				scrollToFaq={scrollToFaq}
				setOpenModal={setOpenModal}
			/>
			<Container style={{ padding: '0' }}>
				<div className={styles.introWrapper}>
					<section className={styles.intro}>
						<div className={styles.introTextWrapper}>
							<h1 className={styles.introText}>
								Construa a BB Seguros do futuro. Torne-se um <span className={styles.sublimeText}>SegTester.</span>
							</h1>
						</div>
					</section>
				</div>
			</Container>
			<WhatIs ref={whatIsSectionRef} fullpage={true} scrollToFaq={scrollToFaq} />
			<Container width="1056px">
				<section ref={signUpSectionRef} className={styles.signUp}>
					<Grid container spacing={10}>
						{!mobile && (
							<Grid item xs={4}>
								<img src={DogImage} alt="Imagem cachorro" className={styles.dogImage} />
							</Grid>
						)}
						<Grid item xs={mobile ? 12 : 8}>
							<div>
								<div>
									<Title fontSize={mobile ? '22px' : '32px'}>Quero ser SegTester!</Title>
									<DividerTitle />
									{!mobile && (
										<Paragraph>
											Para se tornar SegTester, é só preencher o formulário abaixo. Você não precisa ser cliente do BB
											para participar. Após o cadastro, você terá acesso a uma área exclusiva com os testes e pesquisas
											disponíveis!
										</Paragraph>
									)}
								</div>
								{mobile ? (
									<Button
										fullWidth
										sx={{
											fontFamily: 'bb-title-bold',
											bgcolor: theme.palette.secondary.main,
											padding: '10px 40px'
										}}
										type="submit"
									>
										CADASTRE-SE
									</Button>
								) : (
									<div className={styles.formWrapper}>
										<form onSubmit={handleSubmit(onSubmit)}>
											<Grid container spacing={3}>
												<Grid item sm={6}>
													<Controller
														control={control}
														name="nr_cpf"
														rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
														render={({ field: { value, name, onChange }, fieldState: { error } }) => {
															return (
																<TextField
																	sx={{ bgcolor: '#F0F2F4' }}
																	variant="filled"
																	label="CPF"
																	name={name}
																	error={!!error}
																	onChange={event => onChange(maskCpf(event.target.value))}
																	value={value}
																	helperText={error && 'CPF inválido.'}
																	fullWidth
																	placeholder="000.000.000-00"
																/>
															);
														}}
													/>
												</Grid>
												<Grid item sm={6}>
													<Controller
														control={control}
														name="dt_birth"
														rules={{ required: true }}
														render={({ field: { name, onChange, value }, fieldState: { error } }) => {
															return (
																<TextField
																	InputLabelProps={{ shrink: true }}
																	sx={{ bgcolor: '#F0F2F4' }}
																	variant="filled"
																	type="date"
																	label="Data de nascimento"
																	name={name}
																	onChange={onChange}
																	value={value}
																	error={!!error}
																	helperText={error && 'Preecha o campo corretamente.'}
																	placeholder="CPF"
																	fullWidth
																/>
															);
														}}
													/>
												</Grid>
												<Grid item sm={12}>
													<Controller
														control={control}
														name="txt_name"
														rules={{ required: true }}
														render={({ field: { name, onChange, value }, fieldState: { error } }) => {
															return (
																<TextField
																	sx={{ bgcolor: '#F0F2F4' }}
																	variant="filled"
																	type="text"
																	label="Nome"
																	name={name}
																	onChange={onChange}
																	value={value}
																	error={!!error}
																	helperText={error && 'Preecha o campo corretamente.'}
																	placeholder="Exemplo: João Silva"
																	fullWidth
																/>
															);
														}}
													/>
												</Grid>
												<Grid item sm={12}>
													<Controller
														control={control}
														name="txt_email"
														rules={{ required: true }}
														render={({ field: { name, onChange, value }, fieldState: { error } }) => {
															return (
																<TextField
																	sx={{ bgcolor: '#F0F2F4' }}
																	variant="filled"
																	type="email"
																	label="Email"
																	name={name}
																	onChange={onChange}
																	value={value}
																	error={!!error}
																	helperText={error && 'Email inválido..'}
																	placeholder="Exemplo: joao_silva@gmail.com"
																	fullWidth
																/>
															);
														}}
													/>
												</Grid>
											</Grid>
											<div className={styles.registerButtonWrapper}>
												<FormControlLabel
													sx={{
														'& .MuiFormControlLabel-asterisk': {
															display: 'none'
														}
													}}
													labelPlacement="end"
													label={
														<p
															style={{
																fontFamily: 'bb-text-regular',
																fontSize: '16px',
																color: '#111214',
																lineHeight: '22px'
															}}
														>
															Autorizo a BB Seguros a utilizar meus dados pessoais (Nome, CPF, data de nascimento e
															e-mail) para consulta e estudo na elaboração de novos produtos e serviços, seguindo as
															diretrizes da <u>Política de Privacidade</u>. Entendo que poderei a qualquer tempo revogar
															esse consentimento em minha área logada ou através do e-mail{' '}
															<u>privacidade@bbseg.com.br</u>.
														</p>
													}
													control={<Checkbox required />}
												/>
												<Button
													sx={{
														fontFamily: 'bb-title-bold',
														bgcolor: theme.palette.secondary.main,
														padding: '10px 40px'
													}}
													type="submit"
												>
													CADASTRAR
												</Button>
											</div>
										</form>
									</div>
								)}
							</div>
						</Grid>
					</Grid>
				</section>
			</Container>
			<Faq ref={faqSectionRef} />
			<section className={styles.contactWrapper}>
				<Container>
					<Grid container spacing={5}>
						<Grid item xs={12} sm={6}>
							<div>
								<Title fontSize="12px">BB SEGUROS</Title>
								<Title fontSize="25px" style={{ marginTop: '16px' }}>
									Pra tudo que importa
								</Title>
								<Paragraph style={{ marginTop: '16px', color: '#111214BF' }}>
									Transformamos a vida das pessoas por meio do melhor ecossistema de proteção. Somos uma empresa leve
									que gera valor sustentável.
								</Paragraph>
							</div>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Title fontSize="12px">CONTATO</Title>
							<div style={{ marginTop: '20px' }}>
								<div className={styles.phoneContact}>
									<Phone sx={{ marginRight: '8px', color: '#465EFF', width: '30px' }} />
									<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
										SAC 0800-729-7000
									</Paragraph>
								</div>
								<Paragraph fontSize="12px" style={{ color: '#111214BF' }}>
									Deficientes auditivos / fala 0800-962-7373
								</Paragraph>
							</div>
							<div style={{ marginTop: '20px' }}>
								<div className={styles.phoneContact}>
									<Phone sx={{ marginRight: '8px', color: '#465EFF', width: '30px' }} />
									<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
										Ouvidoria 0800-880-2930
									</Paragraph>
								</div>
								<Paragraph fontSize="12px" style={{ color: '#111214BF' }}>
									Deficientes auditivos / fala 0800-775-7003
								</Paragraph>
							</div>
							<div style={{ marginTop: '20px' }}>
								<div className={styles.locationContact}>
									<LocationOn sx={{ marginRight: '8px', color: '#465EFF', width: '30px' }} />
									<Paragraph>
										<a
											href="https://bbseguros.com.br"
											target="_blank"
											style={{ textDecoration: 'none', color: '#111214BF' }}
										>
											bbseguros.com.br
										</a>
									</Paragraph>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={3}>
							{!mobile && <img style={{ width: '175px' }} src={LogoBBBlue} alt="Logo BB Seguros Azul" />}
							<div style={{ marginTop: mobile ? '5px' : '20px' }}>
								<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
									© BB Seguridade Participações S/A
								</Paragraph>
								<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
									CNPJ: 17.344.597/0001-94
								</Paragraph>
							</div>
							<div style={{ marginTop: '20px' }}>
								<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
									SAUN Q5 Lote B, 3º andar - Brasília/DF
								</Paragraph>
								<Paragraph fontSize="15px" style={{ color: '#111214BF' }}>
									CEP: 70040912
								</Paragraph>
							</div>
						</Grid>
					</Grid>
				</Container>
			</section>
			<footer className={styles.footer}>
				<Stack direction="column" sx={{ alignItems: 'center' }}>
					<span className={styles.footerTitle}>Esteja conectado conosco!</span>
					<div style={{ display: 'flex', alignItems: 'center', gap: '35px', margin: '30px 0 30px 0' }}>
						<a href="https://facebook.com/BBSeguros" target="_blank">
							<img style={{ width: '30px' }} src={FacebookIcon} alt="Icone Facebook" />
						</a>
						<a href="https://instagram.com/bbseguros" target="_blank">
							<img style={{ width: '30px' }} src={InstagramIcon} alt="Icone Instagram" />
						</a>
						<a href="https://linkedin.com/company/bb-seguros" target="_blank">
							<img style={{ width: '30px' }} src={LinkedinIcon} alt="Icone Linkedin" />
						</a>
						<a href="https://youtube.com/@bbseguros" target="_blank">
							<img style={{ width: '30px' }} src={YoutubeIcon} alt="Icone Youtube" />
						</a>
					</div>
					<span className={styles.footerTitle}>{`BB Seguros © ${new Date().getFullYear()}`}</span>
				</Stack>
			</footer>
		</>
	);
};

export default HomePublic;
