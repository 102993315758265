import Paragraph from '../Paragraph';
import Title from '../Title';
import MyProfileIllustrator from '../../assets/images/svgs/user-profile.svg';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useBetaTesters from '../../contexts/useBetaTestersContext';

const QuestionDone = () => {
	const navigate = useNavigate();

	const { setQuestionMobileModalOpen } = useBetaTesters();

	return (
		<Grid item xs={6}>
			<Title fontSize="22px">Muito obrigado pelas suas respostas!</Title>
			<Paragraph fontSize="18px" style={{ color: '#66707D', marginTop: '12px' }}>
				Elas são muito importantes para continuarmos evoluindo a BB Seguros e a Comunidade BB Testers.
			</Paragraph>
			<img style={{ marginTop: '19px' }} src={MyProfileIllustrator} alt="Questionário concluído" />
			<Button
				onClick={() => {
					navigate('/');
					setQuestionMobileModalOpen(false);
				}}
				sx={{
					fontFamily: 'bb-title-bold',
					fontSize: '14px',
					color: '#3354FD',
					backgroundColor: '#FDF429',
					padding: '12px 16px',
					marginTop: '50px',
					marginBottom: '50px'
				}}
			>
				IR PARA MEUS TESTES
			</Button>
		</Grid>
	);
};

export default QuestionDone;
